import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Navbar from '../Navbar';
import Footer from '../Footer';
import useFetch from '../../Hooks/useFetch';
import useHandleDelete from '../../Hooks/useHandleDelete';
import useHandleSubmit from '../../Hooks/useHandleSubmit';
import LoadingSpinner from '../LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../store/adminSlice';

function ProjectDetails() {
  const { pname } = useParams();
  const decodedPName = decodeURIComponent(pname);
  const navigate = useNavigate();

  const [fileInputState, setFileInputState] = useState('');
  const [image, setImage] = useState(null);
  const [previewSource, setPreviewSource] = useState('');
  const [projname, setProjName] = useState('');
  const [projdescription, setProjDescription] = useState('');
  const [projGlink, setProjGlink] = useState('');
  const [projDlink, setProjDlink] = useState('');

  const admin = useSelector(selectIsAdmin);

  const [technologies, setTechnologies] = useState(['']);
  const [keyFeatures, setKeyFeatures] = useState(['']); // Initialize as an array

  const handleTechChange = (e, index) => {
    const updatedTech = [...technologies];
    updatedTech[index] = e.target.value;
    setTechnologies(updatedTech);
  };

  const addTech = () => {
    setTechnologies([...technologies, '']);
  };

  const removeTech = (index) => {
    const updatedTech = [...technologies];
    updatedTech.splice(index, 1);
    setTechnologies(updatedTech);
  };

  const handleKeyFeatureChange = (e, index) => {
    const updatedKeyFeatures = [...keyFeatures];
    updatedKeyFeatures[index] = e.target.value;
    setKeyFeatures(updatedKeyFeatures);
  };

  const addKeyFeature = () => {
    setKeyFeatures([...keyFeatures, '']);
  };

  const removeKeyFeature = (index) => {
    const updatedKeyFeatures = [...keyFeatures];
    updatedKeyFeatures.splice(index, 1);
    setKeyFeatures(updatedKeyFeatures);
  };

  const body = {
    projname,
    projGlink,
    projDlink,
    projdescription,
    image,
    technologies,
    keyFeatures,
  };

  const { fetchData, setFetchData, fetchError, isFetchLoading } = useFetch(`project-details/${pname}`);
  const { handleDelete, deleteError, isDeleteLoading } = useHandleDelete(`delete-project-details/${pname}`, setFetchData);

  const onSuccess = () => {
    setFileInputState('');
    setImage(null);
    setPreviewSource('');
    setProjName('');
    setProjDescription('');
    setProjGlink('');
    setProjDlink('');
    setTechnologies(['']);
    setKeyFeatures(['']);
  };

  const { handleSubmit, submitError, isSubmitLoading } = useHandleSubmit(`add-project-details/${decodedPName}`, body, setFetchData, onSuccess);

  const goBack = () => {
    navigate('/projects');
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setFileInputState(e.target.value);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
      setImage(reader.result);
    };
  };

  return (
    <>
      <Navbar />
      <span onClick={goBack} className="flex items-center font-bold cursor-pointer hover:bg-slate-200 rounded-lg my-5 px-4 py-2 text-red-800">
        <FaArrowLeft className="inline-block mr-2" size={20} />
        <span className="text-xs sm:text-base">Back to Projects</span>
      </span>
      <h1 className="text-center text-2xl font-bold mb-4">Explore {decodedPName} Projects</h1>

      {admin && (
        <div className="p-5 sm:mx-2 flex justify-around items-center flex-wrap">
          <div className="w-full max-w-md mx-auto">
            <h1 className="text-center text-2xl font-bold mb-4">Add {decodedPName} Projects</h1>

            <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Project Name</label>
                <input
                  type="text"
                  name="projname"
                  value={projname}
                  onChange={(e) => setProjName(e.target.value)}
                  placeholder={`Enter ${decodedPName}'s Project Name`}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Project Description</label>
                <textarea
                  name="projdescription"
                  rows="4"
                  cols="50"
                  value={projdescription}
                  onChange={(e) => setProjDescription(e.target.value)}
                  placeholder="Enter project description"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                ></textarea>
              </div>

              {/* Key Features */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Key Features</label>
                {keyFeatures.map((feature, index) => (
                  <div key={index} className="flex mb-2">
                    <input
                      type="text"
                      value={feature}
                      onChange={(e) => handleKeyFeatureChange(e, index)}
                      placeholder={`Feature ${index + 1}`}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <button
                      type="button"
                      onClick={() => removeKeyFeature(index)}
                      className="ml-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addKeyFeature}
                  className="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Add Key Feature
                </button>
              </div>

              {/* Technologies Used */}
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Technology Used</label>
                {technologies.map((tech, index) => (
                  <div key={index} className="flex mb-2">
                    <input
                      type="text"
                      value={tech}
                      onChange={(e) => handleTechChange(e, index)}
                      placeholder={`Technology ${index + 1}`}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <button
                      type="button"
                      onClick={() => removeTech(index)}
                      className="ml-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addTech}
                  className="mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Add Technology
                </button>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Github Link</label>
                <input
                  type="text"
                  name="projGlink"
                  value={projGlink}
                  onChange={(e) => setProjGlink(e.target.value)}
                  placeholder="Enter Github link"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Demo Link</label>
                <input
                  type="text"
                  name="projDlink"
                  value={projDlink}
                  onChange={(e) => setProjDlink(e.target.value)}
                  placeholder="Enter demo link"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Image</label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  value={fileInputState}
                  onChange={handleFileInputChange}
                  className="py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  className="bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  {isSubmitLoading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>

          {previewSource && <img src={previewSource} alt="chosen" className="max-w-xs mx-auto" />}
        </div>
      )}

      <div className="flex flex-col md:flex-row justify-around">
        {isFetchLoading ? (
          <LoadingSpinner />
        ) : fetchData && fetchData.length > 0 ? (
          fetchData.map((p) => (
            <div className="md:w-5/12 p-5 flex flex-col justify-center items-center hover:bg-slate-100 mb-4" key={p._id}>
              <Link to={`/projects/${decodedPName}/${encodeURIComponent(p.projname)}`}> {p.imgUrl && (
                <img src={p.imgUrl} alt={p.projname} className="w-full rounded-2xl mb-2" />
              )}
                <Link to={`/projects/${encodeURIComponent(p.pname)}`}>
                  <h1 className="text-center text-sm md:text-xl text-red-800 font-bold my-4">{p.projname}</h1>
                </Link>
                {p.projdescription.length > 20 ? (
                  <>
                    {`${p.projdescription.substring(0, 50)}... `}
                    <span className="text-blue-500 cursor-pointer hover:underline text-sm md:text-base">See more</span>
                  </>
                ) : (
                  p.projdescription
                )}


                {
                  admin &&
                  <div className='my-2'>


                    <button
                      className="px-6 py-1.5 rounded-lg bg-red-600 text-white font-bold text-sm"
                      onClick={() => handleDelete(p._id)}
                      disabled={isDeleteLoading}
                    >
                      {isDeleteLoading ? 'Deleting...' : 'Delete'}
                    </button>
                  </div>

                }
              </Link>
            </div>
          ))
        ) : (
          <div className="w-full text-center font-bold text-red-600 text-lg">No Projects Found</div>
        )}
      </div>


      {fetchError && <p className="text-red-500">{fetchError}</p>}
      {deleteError && <p className="text-red-500">{deleteError}</p>}
      {submitError && <p className="text-red-500">{submitError}</p>}

      <Footer />
    </>
  );
}

export default ProjectDetails;
