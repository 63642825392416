import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    admin: null,
  },
  reducers: {
    AdminLogin: (state, action) => {
      state.admin = action.payload;
      document.cookie = `jwt=${action.payload}; max-age=${60 * 60 * 24}; path=/`;
    },
    AdminLogout: (state) => {
      state.admin = null;
      document.cookie = "jwt=; max-age=0; path=/";
    },
  },
});

export const { AdminLogin, AdminLogout } = adminSlice.actions;

export const selectIsAdmin = (state) => state.admin.admin; 

export default adminSlice.reducer;
