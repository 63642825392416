import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdHand } from 'react-icons/io';
import sign from "../../images/signature.png"
const About = () => {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      {/* Introduction */}
      <div className="mb-8">
        <h1 className="font-bold text-3xl md:text-4xl mb-4">
          Hey !! What's going on ? <IoMdHand className='inline-block text-yellow-500 text-6xl' />
        </h1>
        <p className="text-base md:text-lg mb-8 text-gray-800">
          I am Milton Gaire, a passionate full-stack developer creating
          seamless user experiences.
        </p>
      </div>

      {/* Biography */}
      <div className="mb-8">
        <p className="text-base md:text-lg mb-8 text-gray-800">
          My journey into web development began with a curiosity for technology
          and has grown into a fulfilling career path.
        </p>
      </div>

      {/* Education */}
      <div className="mb-8">
        <h2 className="font-semibold text-xl md:text-2xl mb-4 text-fuchsia-600">
          Education
        </h2>
        <ul className="list-disc list-inside text-base md:text-lg text-gray-800">
          <li>
            Diploma in Software Engineering from  <Link className='font-semibold underline text-fuchsia-600' to={"https://www.centennialcollege.ca/"}>Centennial College</Link>
          </li>
        </ul>
      </div>

      {/* Skills and Expertise */}
      <div className="mb-8">
        <h2 className="font-semibold text-xl md:text-2xl mb-4 text-fuchsia-600">
          Skills and Expertise
        </h2>
        <div className="mb-4">
          <h3 className="font-medium text-lg md:text-xl mb-2 text-fuchsia-600">
            Technical Skills
          </h3>
          <div className="flex flex-wrap gap-2">
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Frontend: HTML, CSS (Bootstrap, Tailwind), JavaScript (React.js), Next.js
            </span>
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Backend: Node.js (Express.js), Python (Django)
            </span>
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Database: MongoDB, SQL (MySQL, PostgreSQL, PL/SQL)
            </span>
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Other: Version control (Git & GitHub), CI/CD, UX/UI (Figma) , Postman, Jest.js
            </span>
          </div>
        </div>
        <div className="mb-4">
          <h3 className="font-medium text-lg md:text-xl mb-2 text-fuchsia-600">
            Soft Skills
          </h3>
          <div className="flex flex-wrap gap-2">
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Problem-solving
            </span>
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Teamwork
            </span>
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Communication
            </span>
            <span className="px-2 py-1 bg-gray-200 text-gray-800 rounded-md text-sm">
              Time management
            </span>
          </div>
        </div>
      </div>

      {/* Experience and Projects */}
      <div className="mb-8">
        <h2 className="font-semibold text-xl md:text-2xl mb-4 text-fuchsia-600">
          Experience and Projects
        </h2>

        {/* Professional Experience */}
        <div className="mb-4">
          <h3 className="font-medium text-lg md:text-xl mb-2 text-fuchsia-600">
            Professional Experience
          </h3>

          <ul className="list-disc list-inside text-base md:text-lg text-gray-800">
            <li>
              Don't have any professional experience yet. Looking for an opportunity.
            </li>
          </ul>
        </div>

        {/* Notable Projects */}
        <div className="mb-4">
          <h3 className="font-medium text-lg md:text-xl mb-2 text-fuchsia-600">
            Notable Projects
          </h3>
          <div className="grid grid-cols-1 gap-4">
            <div className="border border-gray-200 rounded-md p-4">
              <h4 className="font-medium text-lg text-fuchsia-600 mb-2">
                Royal Naan Curry
              </h4>
              <p className="text-base md:text-lg mb-2 text-gray-800">
                A full-stack web application for an online restaurant.
              </p>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-sm md:text-base text-gray-600">
                    Technologies: React.js, Node.js, Express.js, MongoDB
                  </p>
                  <p className="text-sm md:text-base text-gray-600">
                    Features: User authentication, RESTful APIs, cart
                    functionality, admin dashboard
                  </p>
                </div>
                <div>
                  <a
                    href="https://royalnaancurry.miltongaire.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-fuchsia-600 hover:underline"
                  >
                    Live Demo
                  </a>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 rounded-md p-4">
              <h4 className="font-medium text-lg text-fuchsia-600 mb-2">
                Blog Platform
              </h4>
              <p className="text-base md:text-lg mb-2 text-gray-800">
                A blog application deployed on Hostinger VPS.
              </p>
              <div className="flex justify-between items-center">
                <div>
                  <p className="text-sm md:text-base text-gray-600">
                    Technologies: MERN stack
                  </p>
                  <p className="text-sm md:text-base text-gray-600">
                    Features: User registration, post creation, commenting system
                  </p>
                </div>
                <div>
                  <a
                    href="https://blog.miltongaire.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-fuchsia-600 hover:underline"
                  >
                    Live Demo
                  </a>{' '}
                </div>
              </div>
            </div>

            <Link to={"/projects"} className='text-center'><button className='bg-fuchsia-500 text-white font-semibold px-6 py-1 rounded-3xl'>See more Projects..</button></Link>
          </div>
        </div>
      </div>

      {/* Personal Interests and Hobbies */}
      <div className="mb-8">
        <h2 className="font-semibold text-xl md:text-2xl mb-4 text-fuchsia-600">
          Personal Interests and Hobbies
        </h2>
        <p className="text-base md:text-lg mb-8 text-gray-800">
          When I'm not coding, I enjoy playing Cricket, listening music. <br />
          These activities help me stay creative and inspired.
        </p>
      </div>

      {/* Contact Information */}
      <div className="mb-8">
        <h2 className="font-semibold text-xl md:text-2xl mb-4 text-fuchsia-600">
          Contact Information
        </h2>
        <ul className="list-disc list-inside text-base md:text-lg text-gray-800">
          <li>
            Email:{' '}
            <a
              href="mailto:milton.gaire264@gmail.com"
              className="text-fuchsia-600 hover:underline"
            >
              milton.gaire264@gmail.com
            </a>
          </li>
          <li>
            LinkedIn:{' '}
            <a
              href="https://www.linkedin.com/in/milton-gaire-45389a293/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-fuchsia-600 hover:underline"
            >
              linkedin.com/in/milton-gaire-45389a293/
            </a>
          </li>
          <li>
            GitHub:{' '}
            <a
              href="https://github.com/Captainbrooks"
              target="_blank"
              rel="noopener noreferrer"
              className="text-fuchsia-600 hover:underline"
            >
              github.com/Captainbrooks
            </a>
          </li>
        </ul>
      </div>

      {/* Call to Action */}
      <div className="mb-8">
        <h2 className="font-semibold text-xl md:text-2xl mb-4 text-fuchsia-600">
          Call to Action
        </h2>
        <p className="text-base md:text-lg mb-5 text-gray-800">
          Interested in collaborating or have any questions? Get in touch!
        </p>
      </div>

      {/* Closing */}
      <div>
        <h1 className="font-bold text-2xl md:text-3xl mb-4 text-fuchsia-600">
          Stay awesome!
        </h1>
        <img src={sign} className='w-64 sm:w-96 ' alt="signature" />
      </div>
    </div>
  );
};

export default About;
