import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaGithub, FaLinkedin } from 'react-icons/fa';

function Footer() {
  return (

    <>
      <div className='bg-stone-50 mt-5  bottom-0 w-full '>
        <div className='w-3/4 mx-auto flex justify-center '>
          <div className='flex items-center space-x-8 py-4'>
            <a href="https://facebook.com/gairemilton/">
              <FaFacebook className='text-gray-600 text-2xl' />
            </a>
            <a href="https://facebook.com/gairemilton/">
              <FaInstagram className='text-gray-600 text-2xl' />
            </a>
            <a href="https://twitter.com/miltongaire">
              <FaTwitter className='text-gray-600 text-2xl' />
            </a>
            <a href="https://github.com/CaptainBrooks">
              <FaGithub className='text-gray-600 text-2xl' />
            </a>
            <a href="https://www.linkedin.com/in/milton-gaire-45389a293/">
              <FaLinkedin className='text-gray-600 text-2xl' />
            </a>
          </div>
        </div>
        
        <p className='text-gray-600 w-3/4 mx-auto flex justify-center mt-1 mb-4 text-xs'>© 2024 Milton Gaire</p>
      </div>
    </>
  );
}

export default Footer;
