import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import LoadingSpinner from '../LoadingSpinner';
import Footer from '../Footer';
import { FaArrowLeft } from 'react-icons/fa';

function EachProject() {
    const { projectname } = useParams();
    const decodedProjectName = decodeURIComponent(projectname);

    const [project, setProject] = useState("");
    const [isloading, setIsLoading] = useState(false)

    const navigate = useNavigate();


    const goBack = () => {
        navigate(-1);
    };

    

    useEffect(() => {

        console.log(`${process.env.REACT_APP_BASE_URL}`)

        setIsLoading(true)

        const getEachProject = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/getproject/${decodedProjectName}`, {
                method: "GET"
            });

            const json = await response.json();

            if (response.ok) {
                setProject(json.projects)
                setIsLoading(false)
            }
            else {

                setIsLoading(false)
            }
        }
        getEachProject();

    }, [decodedProjectName])

    return (
        <>
            <Navbar />
            <span onClick={goBack} className="flex items-center font-bold cursor-pointer hover:bg-slate-200 rounded-lg my-5 px-4 py-2 text-red-800">
                <FaArrowLeft className="inline-block mr-2" size={20} />
                <span className="text-xs sm:text-base">Back</span>
            </span>

            <div>
                {
                    isloading ? (<LoadingSpinner />) : (

                        project &&

                        <div className='flex flex-col justify-center items-center  md:p-5 mx-6 md:mx-10'>

                            <img className='md:h-full shadow-md object-cover' src={project.imgUrl} alt='project' />
                            <h1 className='text-center font-bold my-8 text-2xl'>{project.projname}</h1>


                            <div>
                                <h2 className="text-lg font-semibold mb-2">Description:</h2>
                                <p className='text-gray-600 my-6'>{project.projdescription}</p>
                            </div>

                            <div className="mt-10">
                                <h2 className="text-lg font-semibold mb-2">Key Features:</h2>
                                <ul className="list-disc ml-6">
                                    {project.keyFeatures.map((feature, index) => {
                                        // Find the index of ":"
                                        const colonIndex = feature.indexOf(':');
                                        if (colonIndex !== -1) {
                                            // Split the feature at the colon
                                            const beforeColon = feature.slice(0, colonIndex);
                                            const afterColon = feature.slice(colonIndex + 1);
                                            return (
                                                <li key={index} className='mb-2'>
                                                    <span className="font-bold">{beforeColon}</span>: {afterColon}
                                                </li>
                                            );
                                        } else {
                                            // If no colon found, display normally
                                            return <li key={index}>{feature}</li>;
                                        }
                                    })}
                                </ul>
                            </div>


                            <div className="my-10">
                                <h2 className="text-lg font-semibold mb-2">Technologies Used:</h2>
                                <ul className="list-disc ml-6">
                                    {project.technologies.map((tech, index) => {

                                        const colonIndex = tech.indexOf(':');
                                        if (colonIndex !== -1) {

                                            const beforeColon = tech.slice(0, colonIndex);
                                            const afterColon = tech.slice(colonIndex + 1);
                                            return (
                                                <li key={index} className='mb-2'>
                                                    <span className="font-bold">{beforeColon}</span>: {afterColon}
                                                </li>
                                            );
                                        } else {

                                            return <li key={index}>{tech}</li>;
                                        }
                                    })}
                                </ul>
                            </div>

                            <div className="mb-4 flex justify-center space-x-6">
                                <a
                                    href={project.projDlink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                                >
                                    Visit Site
                                </a>
                                <a
                                    href={project.projGlink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="inline-block bg-gray-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                                >
                                    Github Link
                                </a>
                            </div>
                        </div>
                    )}
            </div>
            <Footer />
        </>
    );
}

export default EachProject;
