import React, { useState, useEffect } from 'react';
import milton from "../images/milton.jpg";
import { FaFacebook, FaBirthdayCake, FaLink, FaCheck } from 'react-icons/fa';
import { BiCalendar } from 'react-icons/bi';
import { MdLaptopMac } from 'react-icons/md';
import Navbar from './Navbar';
import Lottie from 'lottie-react';
import rocket from "../images/rocket.json";
import { json, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminLogout, selectIsAdmin } from '../store/adminSlice';

function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmit, setIsSubmit] = useState(false)

  const admin = useSelector(selectIsAdmin)

  const dispatch = useDispatch();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isSubmit) {
      const timer = setTimeout(() => {
        setIsSubmit(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isSubmit]);



  const handleSubcription = async (e) => {

    e.preventDefault();

    const body = { email }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user-subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      const json = await response.json();

      if (response.ok) {
        setEmail("");
        setError(null);


        setIsSubmit(json.isSubmit)

      }
    } catch (error) {
      setIsSubmit(false)
      setError(json);
    }
  }








  const handleLogout = () => {

    dispatch(AdminLogout())

  }

  return (
    <>

      <Navbar />

      <div className="relative">
        <div className='bg-sky-100 w-full h-56 flex justify-center items-end'>
          <Lottie
            animationData={rocket}  
          className='h-full'
          />
        </div>

        <div className='absolute top-40 md:top-28 left-4 md:left-20 mb-4 w-100 h-50 rounded-full border-4 border-black text-center text-gray-800 overflow-hidden'>

          <img
            src={milton}
            onClick={openModal}
            className='w-20 h-20 md:w-36 md:h-36 object-cover bg-auto rounded-t-lg img-fluid cursor-pointer'
            alt="milton"
          />
        </div>
      </div>

      <div className='mt-2 mx-1.5 md:mx-10 p p-2 md:px-19 md:py-4 flex flex-wrap justify-between bg-transparent'>
        <div className='md:w-2/3 md:pr-4'>
          <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl text-start mt-2 md:mt-3'>Milton Gaire
          

          </h1>
          {
            admin && <button className='text-white font-semibold text-base bg-red-600 px-5 py-1 rounded-3xl' onClick={handleLogout}>Logout</button>
          }

          <span className='text-gray-600 text-sm md:text-base'>Bring ideas to life with code!</span>
          <p className='mt-1 mb-1 text-xs sm:text-sm md:text-base'>Aspiring Web Developer and Creative Content Creator</p>
          <p className='mb-2 text-xs sm:text-sm md:text-base'>Skilled in MERN stack, Django-Python, and Bootstrap/Tailwind</p>

          <div className="flex gap-2 md:gap-8 lg:gap-10  flex-wrap my-3 sm:mb-0">
            <h4 className='text-slate-700 text-xs md:text-lg font-medium'> <MdLaptopMac className='inline-block m-0.5 size-4' />Available</h4>
            <Link to="/links"> <h4 className='text-pink-500 text-xs md:text-lg font-medium'> <FaLink className='inline-block m-0.5 size-4' /> /Links</h4></Link>
            <h4 className='text-slate-700 font-medium text-xs md:text-lg'><FaBirthdayCake className='inline-block m-0.5 size-4' />25th November</h4>
            <h4 className='text-slate-700 font-medium text-xs md:text-lg'><BiCalendar className='inline-block m-0.5 size-4' /> Joined June 2024</h4>
          </div>
        </div>

        <div>
          <Link to={"https://www.facebook.com/gairemilton/"}><button type="button" className='bg-fuchsia-600 text-zinc-100 font-bold px-6 py-1 rounded-xl'>Follow <FaFacebook className='hidden md:inline-block ml-2 size-6' /></button></Link>
        </div>
      </div>


      {/* info */}

      {isModalOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50" onClick={closeModal}></div>
          <div className="rounded-2xl z-50 flex items-center justify-center">
            <div className="relative w-48 h-48 md:w-72 md:h-72 rounded-full overflow-hidden">
              <img src={milton} className="w-full h-full object-cover" alt="milton" />
              <button className="absolute top-2 right-2 text-gray-400 hover:text-gray-800" onClick={closeModal}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}



      <div className='flex flex-col justify-center items-center  bg-transparent'>
        <h3 className='text-sm md:text-lg text-center text-stone-500'>Keep up to date with my latest projects and adventures!</h3>

        <form className='w-11/12 md:w-1/2 flex justify-center items-baseline' onSubmit={handleSubcription}>
          <input
            type="email"
            className='mt-2 w-9/12 border-2 outline-none h-full p-2 rounded-s-xl focus:border-fuchsia-600'
            placeholder='Email Address'
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button className='text-sm md:text-base bg-fuchsia-600 p-2 rounded-e-xl text-white font-bold h-full w-4/12 md:w-1/4'>Subscribe</button>

        </form>

        {isSubmit && <p className='text-green-600 mt-2 font-semibold'>{isSubmit}<FaCheck className='inline-block' size={20} /></p>}

        {error && <p className='text-red-600 mt-2'>{error}</p>}

      </div>
    </>
  )
}

export default Header;
