import React, { useEffect, useState } from 'react';
import milton from "../../images/milton.jpg";
import { FaStar, FaArrowDown } from 'react-icons/fa';
import { RiCloseLine } from 'react-icons/ri';
import { BsFillPersonFill } from "react-icons/bs";
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import LoadingSpinner from '../LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../store/adminSlice';


function Guestbook() {


  const [formOpen, setFormOpen] = useState(false);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [suggestion, setSuggestion] = useState("")
  const [guestmessages, setGuestMessages] = useState([])
  const [error, setError] = useState("")

  const [isLoading, setIsLoading] = useState(false)

  const admin = useSelector(selectIsAdmin)

  useEffect(() => {

    try {
      const getGuestMessage = async () => {

        setIsLoading(true)


        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/guest-message`, {
          method: "GET",
        });

        const json = await response.json();

        if (response.ok) {
          setError("")
          setGuestMessages(json.GuestMessages)
          setIsLoading(false)
        }

        if (!response.ok) {
          setError(json.error)
          setIsLoading(false)

        }
      }

      getGuestMessage();

    } catch (error) {
      setError(error.message)
    }
  },

    [])

  const toggleFormOpen = () => {
    setFormOpen(!formOpen);
  };

  const handleGuestForm = async (e) => {
    e.preventDefault();
    try {
      const usermessage = { email, suggestion, name }
      setIsLoading(true)

      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/guest-message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(usermessage)
      });

      const json = await response.json();

      if (response.ok) {
        setGuestMessages([json.guest, ...guestmessages])
        setError("");
        setName("")
        setEmail("")
        setSuggestion("")
        setIsLoading(false)
      }
      else {
        setError(json.error)
        setIsLoading(false)
      }
    } catch (error) {
      setError(error.message)
      setIsLoading(false)
    }
  }


  const handleDelete = async (id) => {
    setIsLoading(true)

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-guest-message/` + id, {
      method: "DELETE"
    })

   

    if (response.ok) {
      const remainingGuestMessages = guestmessages.filter((g) => g._id !== id);
      setGuestMessages(remainingGuestMessages)
      setIsLoading(false)

    }
  }
  return (
    <>
      <div className="mt-4 p-4 flex flex-col justify-center items-start">
        <div className='flex items-center mb-1'>
          <img src={milton} className='w-12 h-12 object-cover bg-auto rounded-full' alt="Developer" />
          <div className='ml-2'>
            <h1 className='font-bold'>Milton Gaire <FaStar color="gold" className='inline-block ms-1' size={24} /></h1>
          </div>
        </div>
        <h1 className='text-base font-bold'>Wanna leave me a message? Fill in this form..</h1>
      </div>


      <div className='flex justify-center items-center'>
        <div className="flex justify-between items-center p-5 bg-gray-200 w-full md:w-2/3 cursor-pointer" onClick={toggleFormOpen}>
          <h1 className='font-bold text-fuchsia-900'>GuestBook Form</h1>
          <button>
            {formOpen ? <RiCloseLine className='text-fuchsia-500 hover:bg-gray-300' size={24} /> : <FaArrowDown className='text-fuchsia-500' />}
          </button>
        </div>
      </div>

      <div className='flex justify-center items-center'>


        {formOpen && <div className='w-full md:w-2/3'>


          <form className='bg-gray-200 p-4' onSubmit={handleGuestForm}>
            <h1 className='text-gray-600 text-base font-medium'>Fill in this form and I'll get your message added with <strong className='text-red-700'>Name</strong>.</h1>
            <div className="w-full  my-2">
              <div className="my-2 font-medium">
                <label htmlFor="Title">Name</label>
              </div>
              <input
                type='text'
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter Name..'
                className='border-2  px-3 py-1.5 rounded-sm w-full'
                required
              />
            </div>
            <div className="w-full  my-2">
              <div className="my-2 font-medium">
                <label htmlFor="Title">Email</label>
              </div>
              <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name='email'

                placeholder='Enter email..'
                className='border-2  px-3 py-1.5 rounded-sm w-full'
                required
              />
            </div>

            <div className="w-full my-2">
              <div className="my-2 font-medium">
                <label htmlFor="Message">Message</label>
              </div>

              <textarea
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                name='suggestion'
                placeholder='Enter message..'
                cols='30'
                rows='10'
                className='border-2 px-3 py-1.5 rounded-lg w-full'
                required
              ></textarea>

              <button type='submit' className='text-base bg-fuchsia-500 hover:bg-fuchsia-600 rounded-lg text-white font-medium  py-1 w-full my-3'>
                Submit
              </button>
            </div>
          </form>
        </div>}

      </div>


      <div className='my-8 flex flex-col'>
        <h1 className='text-lg sm:text-2xl font-bold text-center'>Guest Book</h1>



        {isLoading ? (<LoadingSpinner />) : (<div className='flex flex-col flex-wrap w-full'>
          {
            guestmessages.length > 0 ? (guestmessages.map((g) => (
              <div key={g._id} className=' my-2 hover:bg-slate-100 flex flex-col border rounded-md cursor-pointer'>

                <div className='flex items-center mb-1 h-24 '>
                  <BsFillPersonFill className='hover:bg-gray-200 p-0.5 rounded-full text-blue-400' size={40} />
                  <div className='ml-2'>
                    <h1 className='font-bold'>{g.name}
                      <FaStar color="gold" className='inline-block ms-1' size={20} />
                      <FaStar color="gold" className='inline-block ms-1' size={20} />
                      <FaStar color="gold" className='inline-block ms-1' size={20} />
                      <FaStar color="gold" className='inline-block ms-1' size={20} />
                      <FaStar color="gold" className='inline-block ms-1' size={20} />
                    </h1>
                    <p className='text-gray-600 text-sm'>
                      {formatDistanceToNow(new Date(g.createdAt), { addSuffix: true })}
                    </p>
                  </div>
                </div>

                <p className='px-6 py-1 text-gray-600'>{g.suggestion}</p>
                {admin && <button className='my-3 px-6 py-1.5 rounded-lg bg-red-500 hover:bg-red-800 text-white font-bold text-sm ' onClick={() => handleDelete(g._id)}>Delete</button>}
              </div>
            ))) : (
              <h1 className='text-lg font-bold text-center my-5 text-red-600'>No Messages Found</h1>
            )
          }
        </div>)}
        {error && { error }}
      </div>

    </>
  );
}

export default Guestbook;
