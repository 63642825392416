import React, { useState } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { json } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { AdminLogin } from '../store/adminSlice';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState("");
  const [code, setCode] = useState("")
  const [admin, SetAdmin] = useState(false)
  const [adminmessage, setAdminMessage] = useState("")
  const [resendmessage, setResendMessage] = useState("")

  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();

    const user = { email, password };

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/admin-login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });

    const json = await response.json();

    if (response.ok) {

      SetAdmin(true)
      setSuccess(json.message)
      setPassword("")
      setError("")
    } else {
      SetAdmin(false)
      setSuccess("")
      setError(json.error);
    }
  };

  const verifyAdmin = async (e) => {
    e.preventDefault();
    setResendMessage("")

    const verificationcode = { email, code }
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/verify-admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(verificationcode)
      });

      const json = await response.json();

      if (response.ok) {
        setAdminMessage(json.message)
        setResendMessage("")
        SetAdmin(false)

        dispatch(AdminLogin(json.token))

      } else {
        setError("error");
        setAdminMessage(json.error)
      }
    } catch (error) {
      setError(error);
      setAdminMessage(json.error)
    }

  }

  const resendCode = async () => {
    setAdminMessage("")
    const body = { email }

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/resend-verification-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      });

      const json = await response.json();

      if (response.ok) {
        setCode("")
        setResendMessage(json.message)
      } else {
        setResendMessage(json.error)
      }
    } catch (error) {
      setResendMessage(json.error)
    }
  }

  return (
    <>
      <Navbar />
      <div className="flex flex-col justify-center items-center my-4 px-6">
        <h1 className="font-bold text-center my-4 text-2xl">Login Page</h1>
        <form className="border border-gray-300 shadow-md rounded-lg px-10 pt-6 pb-8 mb-4 w-full sm:w-1/2" onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Admin Email:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              placeholder="Enter the email.."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password:
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Enter the password.."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Login
          </button>
        </form>
        <div className='flex justify-center items-center'>
          {admin && success && <div className="text-red-500 font-semibold my-5 text-sm md:text-base">{success}</div>}
        </div>
        {
          admin && (

            <div className='flex flex-col justify-center items-center'>
              <h1 className='text-lg mb-4 font-semibold'>Enter Verification Code</h1>
              <form className='w-3/4' onSubmit={verifyAdmin}>
                <div className="mb-4">
                  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Verification Code
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Enter the verification"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>

                <button
                  className="bg-blue-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Verify
                </button>
                {adminmessage && <p>{adminmessage}</p>}
              </form>

              <div className='my-2'>
                <p className='text-gray-600'>
                  Didn't get a code?{' '}
                  <button
                    className='bg-red-600 text-white px-4 py-1 font-semibold mt-2 rounded-md'
                    disabled={!adminmessage}
                    onClick={resendCode}
                  >
                    Resend code
                  </button>
                </p>
              </div>
              {resendmessage && <p>{resendmessage}</p>}
            </div>
          )
        }
        {error && <div className="text-red-500">{error}</div>}
      </div>

      <Footer />
    </>
  );
}

export default Login;
