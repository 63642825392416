import React, { useEffect, useState } from 'react';
import milton from "../../images/milton.jpg"
import { FaStar } from 'react-icons/fa';
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import LoadingSpinner from "../LoadingSpinner"
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../store/adminSlice';

function Feed() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [posts, setPosts] = useState([])
  const [error, setError] = useState("")
  const [previewSource, setPreviewSource] = useState("");
  const [fileInputState, setFileInputState] = useState(null);
  const [image, setImage] = useState(null);


  const [isloading, setIsLoading] = useState(false)

  const admin = useSelector(selectIsAdmin)

  const handleFileInputChange = (e) => {

    const file = e.target.files[0];

    previewFile(file)
  }

  const previewFile = (file) => {

    // FileReader is a in-built object to asynchronously read the content of files stored in the user computer
    const reader = new FileReader();

    // this readAsDataURl will convert the image we pass into a string
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
      setImage(reader.result);

    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true)
    const post = { title, description, image };

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/createPost`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(post)
      });

      const json = await response.json();

      if (response.ok) {
        setError("");
        setPosts([...posts, json]);
        setFileInputState("")
        setTitle("")
        setDescription("")
        setPreviewSource("")
        setIsLoading(false)
      } else {
        setError('Failed to create post');
        console.error('Error creating post:', json.message);
        setIsLoading(false)
      }
    } catch (error) {
      setError('Failed to create post');
      console.error('Error creating post:', error);
      setIsLoading(false)
    }
  };


  useEffect(() => {
    
    const getPosts = async () => {
      try {
        setIsLoading(true)
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/posts`, {
          method: "GET"
        });

        const json = await response.json();

        if (response.ok) {
          setPosts(json.posts);
          setIsLoading(false)
        } else {
          setError(json.error);
          setIsLoading(false)

        }
      } catch (error) {
        setError(error);
        setIsLoading(false)
      }
    };

    getPosts();
  }, []);

  const handleDelete = async (id) => {

    setIsLoading(true)

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/delete-post/` + id, {
      method: "DELETE"
    })
    if (response.ok) {

      const remainingPosts = posts.filter((p) => p._id !== id);
      setPosts(remainingPosts)
      setIsLoading(false)
    }
  }
  return (
    <div className='flex flex-col justify-center items-center w-full'>

      <h1 className={`${admin ? "block" : "hidden"} font-bold text-center text-xl my-10 md:text-2xl`}>Add a post</h1>

      <form className={` ${admin ? "flex" : "hidden"} flex flex-col w-11/12 sm:w-3/5 justify-center items-center shadow-md shadow-gray-md`} onSubmit={handleSubmit}>

        <div className="w-4/5 my-2">
          <div className="my-2 font-medium">
            <label htmlFor="Title">Title</label>
          </div>
          <input
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            name='title'
            id='title'
            placeholder='Enter Post Title..'
            className='border-2  px-3 py-1.5 rounded-sm w-full'
          />
        </div>
        <div className="w-4/5 my-2">
          <div className="my-2 font-medium">
            <label htmlFor="Description">Description</label>
          </div>

          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name='description'
            id='description'
            placeholder='Enter description..'
            cols='30'
            rows='10'
            className='border-2 px-3 py-1.5 rounded-lg w-full'
          ></textarea>
        </div>

        <div className="w-4/5 my-2 flex flex-col">
          <div className="my-2 font-medium">
            <label htmlFor="Picture">Post Image</label>
          </div>
          <input type="file" name="image" id="image" value={fileInputState} onChange={handleFileInputChange} />

          {previewSource && <img src={previewSource} alt='chosen' className='my-2 border-2 p-4 w-1/2'></img>}

        </div>
        <button type='submit' className='text-lg bg-fuchsia-500 hover:bg-fuchsia-600 rounded-2xl text-white font-medium px-2 py-1 w-3/4 my-3'>
          Submit
        </button>
      </form>
      {error && <p className="text-red-500">{error}</p>}

      {isloading ? (<LoadingSpinner />) : (

        <div className="flex flex-col">

          {posts && posts.map((p) => (
            <div key={p._id} className="w-full  px-2 py-2 my-4  cursor-pointer hover:bg-zinc-100">
              <div className='flex items-center mb-1 '>
                <img src={milton} className='w-12 h-12 object-cover bg-auto rounded-full' alt="milton" />
                <div className='ml-2'> {/* Adjust margin left for spacing */}
                  <h1 className='font-bold'>Milton Gaire
                    <FaStar color="gold" className='inline-block ms-1' size={24} />
                  </h1>
                  <p className='text-gray-600 text-sm'>
                    {formatDistanceToNow(new Date(p.createdAt), { addSuffix: true })}
                  </p>
                </div>
              </div>

              <h1 className="text-base sm:text-lg font-bold my-3 text-red-700">{p.title}</h1>
              <p className="text-gray-700  text-start text-sm md:text-base md:text-md leading-6 mb-2">{p.description}</p>
              <img src={p.imgUrl} alt="post" className="border-4 rounded-lg mb-2 w-full h-full md:h-96 object-contain md:object-cover" />
              {
                admin &&
                <div className="flex justify-end">
                  <button className='px-6 py-1.5 bg-red-600 text-white font-bold text-sm rounded-md hover:bg-red-700  my-4' onClick={() => handleDelete(p._id)}>
                    Delete
                  </button>
                </div>
              }
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Feed;
