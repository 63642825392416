import React from 'react';
import Headroom from 'react-headroom';
import { Link } from 'react-router-dom';
import { FaGithub, FaLinkedin, FaCode } from 'react-icons/fa';

function Navbar() {
  return (
    <Headroom>
      <div className="backdrop-filter backdrop-blur-lg bg-stone-50 bg-opacity-80 text-white px-2 flex justify-between items-center h-full border-b-2 border-slate-50 z-10">
        <Link className='hover:border-fuchsia-800 hover:rounded-full ' to={"/"}><h1 className='text-fuchsia-600 font-bold text-2xl py-4 px-2  '>Milton Gaire <FaCode className='inline-block' /> </h1></Link>
        <div className="text-black flex gap-5">
          <Link to={"https://github.com/Captainbrooks"} className="flex items-center gap-2 p-1 hover:bg-fuchsia-600 hover:text-white">
            <span className="font-semibold hidden md:block">Github</span>
            <FaGithub className="inline-block" size={24} />
          </Link>
          <Link to={"https://www.linkedin.com/in/milton-gaire-45389a293/"} className="flex items-center gap-2 p-1 hover:bg-fuchsia-600 hover:text-white">
            <FaLinkedin size={24} />
            <span className="font-semibold hidden md:block">Linkedin</span>
          </Link>
        </div>
      </div>
    </Headroom>
  );
}

export default Navbar;
