import React from 'react'
import Navbar from '../components/Navbar'
import milton from "../images/milton.jpg";
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

function Links() {
    return (
        <>
            <Navbar />
            <div className='flex flex-col justify-start mx-6 md:mx-20'>

                <img src={milton} className='w-64 my-10 rounded-3xl' alt='milton' />
                <h1 className='text-3xl text-start font-bold'>Link me!</h1>

                <p className='text-gray-600 text-base my-2'>Need a link to find me somewhere? Odds are, it's here.</p>

                <div className='my-5'>
                    <div className="mb-4">
                        <h3 className="font-medium text-lg md:text-xl mb-5 text-fuchsia-600">
                            Social Media Links!
                        </h3>
                        <div className="flex flex-wrap gap-2">
                            <Link to={"https://www.facebook.com/gairemilton/"}> <span className="px-2 py-3 bg-gray-200 text-gray-800 rounded-md text-sm">
                                Facebook
                            </span></Link>
                            <Link to={"https://www.linkedin.com/in/milton-gaire-45389a293/"}><span className="px-2 py-3 bg-gray-200 text-gray-800 rounded-md text-sm">
                                Linkedin
                            </span></Link>
                            <Link to={"https://www.facebook.com/gairemilton/"}><span className="px-2 py-3 bg-gray-200 text-gray-800 rounded-md text-sm">
                                Instagram
                            </span></Link>
                        </div>
                    </div>
                </div>

                <div className='my-5'>

                    <h3 className="font-medium text-lg md:text-xl mb-5 text-fuchsia-600">
                        Projects
                    </h3>
                    <Link to={"/projects"}> <span className="px-2 py-3 bg-gray-200 text-gray-800 rounded-md text-sm">
                        Projects
                    </span></Link>
                </div>

                <div className='my-5'>

                    <h3 className="font-medium text-lg md:text-xl mb-5 text-fuchsia-600">
                        Code
                    </h3>
                    <Link to={"https://github.com/Captainbrooks"}> <span className="px-2 py-3 bg-gray-200 text-gray-800 rounded-md text-sm">
                        Github
                    </span></Link>
                </div>
                <div className='my-5'>

                    <h3 className="font-medium text-lg md:text-xl mb-5 text-fuchsia-600">
                        Feed
                    </h3>
                    <Link to={"/"}> <span className="px-2 py-3 bg-gray-200 text-gray-800 rounded-md text-sm">
                        Feed
                    </span></Link>
                </div>

            </div>

            <Footer />
        </>
    )
}

export default Links
