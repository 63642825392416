import React, { useState } from 'react';
import Feed from './body-components/Feed';
import About from './body-components/About';
import Projects from './body-components/Projects';
import Guestbook from './body-components/Guestbook';
import { Link } from 'react-router-dom';

function Body() {
  const [show, setShow] = useState(<Feed />);
  const [downborder, setDownBorder] = useState("Feed");

  const Components = {
    Feed: <Feed />,
    About: <About />,
    Projects: <Projects />,
    Guestbook: <Guestbook />
  };

  const handleSection = (section) => {
    setShow(Components[section]);
    setDownBorder(section);
  };

  return (
    <>
      <div className="mt-4 mb-10 mx-3 sm:mx-32 flex justify-between sm:justify-around items-center rounded-full">
        <div className={`py-2 px-4 hover:bg-gray-200  cursor-pointer font-bold font-sans text-sm md:text-base ${downborder === 'Feed' ? 'border-b-4 border-fuchsia-500' : ''}`}>
          <button onClick={() => handleSection('Feed')}><h1>Feed</h1></button>
        </div>
        <div className={`py-2 px-4 hover:bg-gray-200  cursor-pointer font-bold font-sans text-sm md:text-base ${downborder === 'About' ? 'border-b-4 border-fuchsia-500' : ''}`}>
          <button onClick={() => handleSection('About')}><h1>About</h1></button>
        </div>
        <div className={`py-2 px-4 hover:bg-gray-200  cursor-pointer font-bold font-sans text-sm md:text-base ${downborder === 'Projects' ? 'border-b-4 border-fuchsia-500' : ''}`}>
          <Link to="/projects"><button onClick={() => handleSection('Projects')}><h1>Projects</h1></button></Link>
        </div>
        <div className={`py-2 px-4 hover:bg-gray-200  cursor-pointer font-bold font-sans text-sm md:text-base ${downborder === 'Guestbook' ? 'border-b-4 border-fuchsia-500' : ''}`}>
          <button onClick={() => handleSection('Guestbook')}><h1>Guestbook</h1></button>
        </div>
      </div>

      <div className="mx-1.5 md:mx-24 mb-10 rounded-lg">
        <div className="">{show}</div>
      </div>
    </>
  );
}

export default Body;
