import { useState } from 'react';

function useHandleDelete(endpoint, setFetchData) {
  const [deleteError, setDeleteError] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const handleDelete = async (id) => {
    setIsDeleteLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/${decodeURIComponent(endpoint)}/${id}`, {
        method: 'DELETE',
      });
      const json = await response.json();

      if (response.ok) {
        setFetchData((prevData) => prevData.filter((item) => item._id !== id));
        setDeleteError(null);
      } else {
        setDeleteError(json.error);
      }
    } catch (error) {
      setDeleteError('Something went wrong. Try again.');
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return { handleDelete, deleteError, isDeleteLoading };
}

export default useHandleDelete;
