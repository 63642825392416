import { useState } from 'react';

function useHandleSubmit(endpoint, body, setFetchData, onSuccess) {
  const [submitdata, setSubmitData] = useState(null);
  const [submiterror, setSubmitError] = useState(null);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const baseUrl =`${process.env.REACT_APP_BASE_URL}`;
      const response = await fetch(`${baseUrl}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const json = await response.json();

      if (response.ok) {
        setSubmitData((prevData) => [...(prevData || []), json.projects]);
        setFetchData((prevData) => [...(prevData || []), json.projects]); // Update fetched data
        onSuccess(); // Call the success callback
        setIsSubmitLoading(false);
      } else {
        setSubmitError(json.error);
        setIsSubmitLoading(false);
      }
    } catch (error) {
      setSubmitError('Something went wrong.');
    }
  };

  return { submitdata, submiterror, isSubmitLoading, handleSubmit };
}

export default useHandleSubmit;
