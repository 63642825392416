import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProjectDetails from './components/body-components/ProjectDetails';
import Projects from './components/body-components/Projects';
import Home from './pages/Home';
import Login from './pages/Login';
import { selectIsAdmin } from './store/adminSlice';
import EachProject from './components/body-components/EachProject';
import Links from './pages/Links';


function App() {
  
    const admin = useSelector(selectIsAdmin);

    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/projects/:pname" element={<ProjectDetails />} />
                    <Route path="/links" element={<Links />} />
                    <Route path="/projects/:pname/:projectname" element={<EachProject />} />
                    <Route path="/admin-login" element={!admin ? <Login/> : <Navigate to="/" />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
