import { useEffect, useState } from 'react';

function useFetch(endpoint) {
  const [fetchData, setFetchData] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isFetchLoading, setIsFetchLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchLoading(true);
      try {
        const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
        const decodedEndPoint = decodeURIComponent(endpoint);
        const response = await fetch(`${baseUrl}/${decodedEndPoint}`, { method: 'GET' });
        const json = await response.json();

        if (response.ok) {
          setFetchData(json.projects);
          setFetchError(null);
        } else {
          setFetchError(json.error);
        }
      } catch (error) {
        setFetchError('Something went wrong. Try again.');
      } finally {
        setIsFetchLoading(false);
      }
    };

    fetchData();
  }, [endpoint]);

  return { fetchData, setFetchData, fetchError, isFetchLoading };
}

export default useFetch;
