import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import Navbar from '../Navbar';
import Footer from '../Footer';
import useFetch from '../../Hooks/useFetch';
import useHandleDelete from '../../Hooks/useHandleDelete';
import useHandleSubmit from '../../Hooks/useHandleSubmit';
import LoadingSpinner from '../LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectIsAdmin } from '../../store/adminSlice';

function Projects() {
  const [fileInputState, setFileInputState] = useState('');
  const [image, setImage] = useState(null);
  const [previewSource, setPreviewSource] = useState('');
  const [pname, setPName] = useState('');
  const navigate = useNavigate();

  const admin = useSelector(selectIsAdmin)

  const { fetchData, setFetchData, fetchError, isFetchLoading } = useFetch('projects-classification');
  const { handleDelete, deleteError, isDeleteLoading } = useHandleDelete('delete-project-classification', setFetchData);

  const onSuccess = () => {
    setFileInputState('');
    setImage(null);
    setPreviewSource('');
    setPName('');
  };

  const { handleSubmit, submitError, isSubmitLoading } = useHandleSubmit('add-project-classification', { pname, image }, setFetchData, onSuccess);

  const goBack = () => {
    navigate('/');
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setFileInputState(e.target.value);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
      setImage(reader.result);
    };
  };

  return (
    <>
      <Navbar />
      <span onClick={goBack} className="flex items-center font-bold cursor-pointer hover:bg-slate-200 rounded-lg my-5 px-4 py-2 text-red-800">
        <FaArrowLeft className="inline-block mr-2" size={20} />
        <span className="text-xs sm:text-base">Back to Home</span>
      </span>

      <h1 className="my-5 font-bold text-center text-lg sm:text-2xl">Explore Projects</h1>
      <div className={admin ? "block" : "hidden"}>
        <h1 className="font-bold text-center">Add a Project Classification</h1>
        <form className="flex flex-col items-center w-full max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg" onSubmit={handleSubmit}>
          <input
            type="text"
            name="pname"
            value={pname}
            onChange={(e) => setPName(e.target.value)}
            placeholder="Project Classification Name"
            className="w-full px-4 py-2 mb-4 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
            disabled={isSubmitLoading}
          />
          <input
            type="file"
            name="image"
            id="image"
            value={fileInputState}
            onChange={handleFileInputChange}
            className="w-full px-4 py-2 mb-4 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:border-gray-500"
            disabled={isSubmitLoading}
          />
          {previewSource && <img src={previewSource} alt="chosen" className="my-4 mx-auto rounded-lg shadow-md" style={{ maxWidth: '100px' }} />}
          <button
            type="submit"
            className="bg-gray-800 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
            disabled={isSubmitLoading}
          >
            {isSubmitLoading ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
      {isFetchLoading ? (
        <div className="text-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="p-5 sm:mx-2 flex justify-around items-center flex-wrap">
          {fetchData.length > 0 ? (
            fetchData.map((p) => (
              <div className="w-full p-4 md:w-1/3 flex flex-col justify-center items-center hover:bg-slate-100" key={p._id}>
                <Link to={`/projects/${encodeURIComponent(p.pname)}`}>
                  <img src={p.imgUrl} alt={p.pname} className="w-96 h-80 md:w-56 md:h-40 rounded-2xl mb-2 object-contain" />
                  <h1 className="text-center text-xl font-medium my-4">{p.pname}</h1>
                </Link>

                {
                  admin &&
                  <button
                    className="px-6 py-1.5 rounded-lg bg-red-600 text-white font-bold text-sm"
                    onClick={() => handleDelete(p._id)}
                    disabled={isDeleteLoading}
                  >
                    {isDeleteLoading ? 'Deleting...' : 'Delete'}
                  </button>

                }
              </div>
            ))
          ) : (
            <div className='text-center text-red-600 text-lg font-bold'>No Projects Found</div>
          )}
        </div>
      )}
      {fetchError && <p className="text-red-500">{fetchError}</p>}
      {deleteError && <p className="text-red-500">{deleteError}</p>}
      {submitError && <p className="text-red-500">{submitError}</p>}
      <Footer />
    </>
  );
}

export default Projects;
